import React, { FC, useState, MouseEvent, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import { Button, Popover } from '@mui/material';

import { translate } from '@utils/locale';
import { RGBAToHexA } from '@utils/common';

import { ColorPickerProps } from './types';

const ColorPicker: FC<ColorPickerProps> = props => {
  const {
    defaultColor,
    handleOnSelect,
    handleOnClose,
    parentElement,
    errorMessage
  } = props;

  const [color, setColor] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onSelect = () => {
    setAnchorEl(null);
    handleOnSelect(color ?? '');
  };

  const onClose = () => {
    setAnchorEl(null);
    handleOnClose?.();
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (defaultColor) setColor(defaultColor);
  }, [defaultColor, anchorEl]);

  return (
    <>
      <div className="w-full" onClick={handleClick}>
        {parentElement}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <div className="flex justify-center items-center h-full">
          <div className="flex flex-col bg-white rounded-md">
            {/* @ts-ignore */}
            <ChromePicker
              disableAlpha={true}
              color={color}
              onChange={clr => {
                //  @ts-ignore
                if (clr.source === 'rgb') {
                  const { r, g, b, a } = clr.rgb;
                  setColor(RGBAToHexA(r, g, b, a ?? 1));
                  return;
                }
                setColor(clr.hex);
              }}
            />

            <Button variant="contained" onClick={onSelect}>
              {translate('colorPicker.button')}
            </Button>
          </div>
        </div>
      </Popover>
      {errorMessage && (
        <p className="mt-[4px] text-xs text-red">{errorMessage}</p>
      )}
    </>
  );
};

export default ColorPicker;
